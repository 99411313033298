






























/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { differentAgeConsumeDetails, marketPenetration } from '@/utils/echarts/echarts';
import { Vue, Component, Watch } from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class extends Vue {
  // @Prop({
  //   type: Array,
  //   required: false,
  // })
  // data!: [];
  data: any = [];
  columns1 = [
    {
      title: '客群年龄',
      key: 'text',
      align: 'center',
    },
    {
      title: '人口数量',
      key: 'num',
      align: 'center',
    },
    {
      title: '商圈内排名',
      key: 'rank',
      align: 'center',
    },
  ];
  marketOptions: any = {};
  tableData1: any = [];
  tableData2: any = [];
  tWidth = 0;
  tHeight = 520;
  resetStatus = true;
  eWidth = 0;
  eHeight = 0;
  created(): void {
    const containerWd = 900;
    const wd = Math.round(containerWd / 2);
    this.tWidth = wd - 40;
    this.eWidth = containerWd;
    // this.eHeight = 500;
  }
  btnSortClickHandler(): void{
    this.resetStatus = !this.resetStatus;
  }

  changeData(data: any): void {
    this.data = data;
    this.tableData1 = [];
    this.tableData2 = [];
    const data2: any = [];
    data.forEach((item: any) => {
      const { sex } = item;
      sex === 'man' ? this.tableData1.push(item) : this.tableData2.push(item);
      data2.push({ value: item.property, label: item.text });
    });
    this.changeReset();
  }

  closeClickHandler(): void {
    this.$emit('updateGridInfoStatus', false);
  }

  get resetText(): string {
    return this.resetStatus === true ? '排序' : '复位';
  }

  @Watch('resetStatus')
  changeReset(): void {
    const xData: string[] = [];
    const manData: string[] = [];
    const menData: string[] = [];
    if (this.resetStatus) {
      this.data.forEach((item: any) => {
        const { num, text, sex } = item;
        if (sex === 'man') {
          xData.push(text.slice(2, -1));
          manData.push(num);
        } else {
          menData.push(num);
        }
      });
      this.marketOptions = differentAgeConsumeDetails(
        xData,
        manData,
        menData,
        3,
        '年龄',
        '人口数',
        '男性',
        '女性',
      );
    } else {
      const data = JSON.parse(JSON.stringify(this.data));
      data.sort((x: any, y: any) => y.num - x.num);
      data.forEach((item: any) => {
        const { num, text, sex } = item;
        xData.push(text);
        if (sex === 'man') {
          manData.push(num);
          menData.push('');
        } else {
          manData.push('');
          menData.push(num);
        }
      });
      this.marketOptions = marketPenetration(
        xData,
        menData,
        manData,
        '女性',
        '男性',
        '人口数',
        1,
      );
    }
  }
}
