



















































import { prefix } from '@/config/global';
import { Vue, Component, Watch } from 'vue-property-decorator';
import  { GridParams }   from '@/types/store';
import  { getGridBussinessCircleS, gridDepressionData }  from '@/services/api';
import rankFilter from '@/components/rankFilter/index.vue';
import rankTable from '@/components/rankTable/index.vue';
import { getMapCenter, initMap, drawMall, clearMall, zoomUp, zoomDown } from '@/components/map/map';
import { Grid } from '@/components/map/grid';
import gradientCard from '@/components/GradientCard/index.vue';
import spinCard from '@/components/SpinCard/index.vue';
import gridInfo from '@/components/gridInfo/index.vue';
import { getContainer, getMax, getMin } from '@/components';
import countUpFn from '@/utils/countUp';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

@Component({
  components: {
    spinCard,
    rankFilter,
    rankTable,
    gradientCard,
    gridInfo,
  },
})


export default class TouristSource extends Vue {
  prefix: string = prefix;
  $stores: any;
  spinShow = true;
  gridInfoStatus = false;
  mallMarker!: any;
  tableWh = 400;
  res: any;
  data: any= [];
  map: any;
  filterValue: number[]=[0, 100];
  duration = '';
  mallId = '';
  date = '';
  dates = '';
  boundary ='';
  center = '';
  params: any = {};
  rowActive = 0;
  initZom = 13;
  step = 0.01;
  max = 100;
  min = 0;
  gridColor = ['rgba(43,50,153, .7)', 'rgba(46,63,176, .7)', 'rgba(50,85,199, .7)', 'rgba(44,99,217, .7)', 'rgba(43,115,224, .7)', 'rgba(41,132,230, .7)', 'rgba(41,145,230, .7)', 'rgba(46,178,255, .7)', 'rgba(91,201,255, .7)'].reverse();
  gridColor2 = ['rgba(255,255,255,.1)'];
  grid: any;
  title = '本市覆盖排名';
  type = 0;
  text = '1平方公里';
  singleColor = 'blue';
  titleDisplay = true;
  gradientWidth = 22;
  gradientList = [
    {
      id: 1,
      color: this.gridColor[0],
      width: this.gradientWidth,
    },
    {
      id: 2,
      color: this.gridColor[1],
      width: this.gradientWidth,
    },
    {
      id: 3,
      color: this.gridColor[2],
      width: this.gradientWidth,
    },
    {
      id: 4,
      color: this.gridColor[3],
      width: this.gradientWidth,
    },
  ];
  gradientValue = [
    {
      id: 1,
      num: 1,
    },
    {
      id: 2,
      num: 2,
    },
    {
      id: 3,
      num: 3,
    },
  ];
  gridColorObj1 = {
    color: this.gridColor,
    top0Color: this.gridColor[0],
    top1Color: 'rgba(254,12,46, .6)',
    strokeStyle: 'rgba(255,255,255,.6)',
    lineWidth: 1,
    textColor: '#ffffff',
  };
  color = 'rgba(255,255,255,.1)';
  gridColorObj2 = {
    color: this.gridColor2,
    top0Color: this.color,
    top1Color: this.color,
    strokeStyle: 'rgba(80,128,215,1)',
    lineWidth: 2,
    textColor: 'black',
  };
  propertyObjData: any = [];
  gridColorObj = this.gridColorObj1;
  mapHeight = '0';
  gridType = 0;
  gridClickType = 0;
  gridFunctionStatus = 1;
  gridFunctionOffStatus = 0;
  propertyList1 = [{ value: 11, label: '男(≤19)' }, { value: 12, label: '男(20-29)' }, { value: 13, label: '男(30-39)' }, { value: 14, label: '男(40-49)' }, { value: 15, label: '男(50-59)' }, { value: 16, label: '男(60-69)' }, { value: 17, label: '男(70-79)' }, { value: 21, label: '女(≤19)' }, { value: 22, label: '女(20-29)' }, { value: 23, label: '女(30-39)' }, { value: 24, label: '女(40-49)' }, { value: 25, label: '女(50-59)' }, { value: 26, label: '女(60-69)' }, { value: 27, label: '女(70-79)' }];
  propertyList2 = [{ value: 10, label: '男(≤17)' }, { value: 11, label: '男(18-24)' }, { value: 12, label: '男(25-30)' }, { value: 13, label: '男(31-35)' }, { value: 14, label: '男(36-40)' }, { value: 15, label: '男(41-45)' }, { value: 16, label: '男(46-50)' }, { value: 17, label: '男(51-55)' }, { value: 18, label: '男(56-60)' }, { value: 19, label: '男(≥61)' }, { value: 20, label: '女(≤17)' }, { value: 21, label: '女(18-24)' }, { value: 22, label: '女(25-30)' }, { value: 23, label: '女(31-35)' }, { value: 24, label: '女(36-40)' }, { value: 25, label: '女(41-45)' }, { value: 26, label: '女(46-50)' }, { value: 27, label: '女(51-55)' }, { value: 28, label: '女(56-60)' }, { value: 29, label: '女(≥61)' }];
  propertyValue = 11;
  propertyList: any = [];
  oldDate = this.date;
  $dayjs: any;
  $beaconAction: any;
  gridInfo = {
    area: 0,
    sum: 0,
  };

  mounted(): void {
    this.filterValue = this.$store.state.pageConfig.groupFilterValue;
    this.mapHeight = `${getContainer()}px`;
    this.map = initMap('map-container', this.initZom, this.center);
  }

  pageHandlers(changeType: string): void{
    this.spinShow = true;
    this.updateGridInfoStatus(false);
    setTimeout(() => {
      this.pageHandler(this.gridType, this.gridColorObj, changeType);
    }, 0);
  }
  updateGridInfoStatus(status: boolean): void {
    if (this.gridInfoStatus !== status) {
      this.gridInfoStatus = status;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async pageHandler(type: number, gridColorObj: any, changeType: string): Promise<void> {
    const { mall } = this.$store.state;
    const {
      boundary,
      center,
      date,
      mallId,
      duration,
      dates,
    } = mall;
    this.boundary = boundary;
    this.center = center;
    this.date = date;
    this.dates = dates;
    this.mallId = mallId;
    this.duration = duration;
    const dates2 = this.date.replaceAll('-', '');
    if (dates2 >= '20210101') {
      this.propertyList = this.propertyList2;
    } else {
      this.propertyList = this.propertyList1;
    }


    let status = 0;
    this.propertyList.forEach((item: any) => {
      if (item.value === this.propertyValue) {
        status = 1;
      }
    });
    if (changeType === 'init' || status === 0) {
      this.propertyValue = this.propertyList[0].value;
    }
    console.log(this.propertyValue, 'this.propertyValue');
    if (this.center) {
      const center = getMapCenter(this.center);
      this.map.setCenter(center);
      this.map.panBy(198, 0);
      clearMall(this.mallMarker);
      this.mallMarker = drawMall(this.map, center);
    }
    this.params = {
      mall_id: this.mallId,
      date: this.date,
      duration: this.duration,
      start_percent: this.filterValue[0],
      end_percent: this.filterValue[1],
      type: 21,
      property: this.propertyValue,
    };

    this.res = await getGridBussinessCircleS(this.params);
    const { data, sum } = this.res;
    this.data = data;

    this.gridInfo.sum = sum;
    this.gridInfo.area = data.length;
    countUpFn('distribution-num', sum);

    if (!this.data) {
      this.data = [];
    }
    // if (type === 1) {
    //   const data = this.data.slice(1);
    //   const data0 = this.data[0];
    //   this.data = processDataByStartEnd(this.filterValue[0], this.filterValue[1], data);
    //   this.data.unshift(data0);
    // }
    this.max = getMax(this.data, 'num');
    this.min = getMin(this.data, 'num');
    this.data.forEach((item: any, index: number) => {
      (this.data[index] as any).index = index;
    });
    this.grid = new (Grid as any)({
      map: this.map,
      data: this.data,
      color: gridColorObj.color,
      weightFiled: 'num',
      weightType: 2,
      rankFiled: 'rank',
      top0Color: gridColorObj.top0Color,
      top1Color: gridColorObj.top1Color,
      strokeStyle: gridColorObj.strokeStyle,
      lineWidth: gridColorObj.lineWidth,
      textColor: gridColorObj.textColor,
    });

    this.grid.gridClick((item: any) => {
      const { property_obj: propertyObj, rank } = item;
      this.propertyObjData = Object.values(propertyObj);
      this.updateGridInfoStatus(true);
      (this.$refs.gridInfo as any).changeData(this.propertyObjData);
      this.buriedPointHandler('demographic_distribution_grid_click', { info: rank });
    });
    this.grid.gridMove();


    this.gradientList = [];
    let index = 0;
    this.gridColor.forEach((item: any) => {
      index = index + 1;
      const obj = {
        id: index,
        color: item,
        width: this.gradientWidth,
      };
      this.gradientList.push(obj);
    });
    const valueCenter = Math.round(this.max / 2 * 100) / 100;
    this.gradientValue = [
      {
        id: 1,
        num: this.min,
      },
      {
        id: 2,
        num: valueCenter,
      },
      {
        id: 3,
        num: this.max,
      },
    ];
    this.spinShow = false;
  }
  @Watch('$store.state.mall', { immediate: false, deep: true })
  changeMall(): void {
    this.gridColorObj = this.gridColorObj1;
    this.gridType = 0;
    this.pageHandlers('init');
  }
  // @Watch('$store.state.global.downloadStatus')
  // changeExport(): void {

  // }
  async getGridDepressionData(params: GridParams): Promise<any> {
    const data = await gridDepressionData(params);
    return data;
  }

  sliderChange(val: number[]): void {
    // 进度条数据变化返回
    this.filterValue = val;
    this.$stores.pageConfig.setGroupFilterValue(val);
    this.gridType = 1;
    this.pageHandlers('paramsChange');
    this.buriedPointHandler('demographic_distribution_rank_select');
  }

  buriedPointHandler(buriedPoint: string, params?: any): void{
    this.$beaconAction.onUserAction(buriedPoint, params);
  }

  zoomUp(): void {
    zoomUp(this.map);
  }

  zoomDown(): void {
    zoomDown(this.map);
  }

  propertyChangeHandler(val: number): void{
    this.propertyValue = val;
    this.pageHandlers('initChange');
    let text = '';
    this.propertyList.forEach((item: any) => {
      if (item.value === this.propertyValue) {
        text = item.label;
      }
    });
    this.buriedPointHandler('demographic_distribution_gender_select', { type: text });
  }

  mallPostionClickHandler(): void{
    const center = getMapCenter(this.center);
    this.map.panTo(center);
    this.map.panBy(198, 0);
  }

  gridFunctionClickHandler(): void{
    // this.grid.destroy();
    if (this.gridClickType === 0) {
      this.gridFunctionStatus = 0;
      this.gridFunctionOffStatus = 1;
      this.gridClickType = 1;
      this.gridColorObj = this.gridColorObj2;
    } else {
      this.gridFunctionStatus = 1;
      this.gridFunctionOffStatus = 0;
      this.gridClickType = 0;
      this.gridColorObj = this.gridColorObj1;
    }
    this.grid = new (Grid as any)({
      map: this.map,
      data: this.data,
      color: this.gridColorObj.color,
      weightFiled: 'num',
      weightType: 2,
      rankFiled: 'rank',
      top0Color: this.gridColorObj.top0Color,
      top1Color: this.gridColorObj.top1Color,
      strokeStyle: this.gridColorObj.strokeStyle,
      lineWidth: this.gridColorObj.lineWidth,
      textColor: this.gridColorObj.textColor,
    });
    this.grid.gridClick((item: any) => {
      const { property_obj: propertyObj, rank } = item;
      this.propertyObjData = Object.values(propertyObj);
      this.updateGridInfoStatus(true);
      (this.$refs.gridInfo as any).changeData(this.propertyObjData);
      this.buriedPointHandler('demographic_distribution_grid_click', { info: rank });
    });
    this.grid.gridMove();
    const item = this.data[this.rowActive];
    setTimeout(() => {
      this.grid.polygonHandler(item);
    }, 0);
  }
}
